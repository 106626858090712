export enum AccountRequirement {
  locationPersonal = 'location.personal',
  personalSsnFull = 'personal.ssnFull',
  businessUrl = 'business.url',
  businessDba = 'business.dba',
  businessPhone = 'business.phone',
  businessType = 'business.type',
  businessClassification = 'business.classification',
  businessIndustry = 'business.industry',
  businessAskAboutAddress = 'business.askAboutAddress',
  businessAddress = 'business.address',
  businessLocation = 'business.location',
  businessSsnLastFour = 'business.ssnLastFour',
  businessEin = 'business.ein',
  businessAskAboutController = 'business.askAboutController',
  controllerTitle = 'controller.title',
  controllerFirstName = 'controller.firstName',
  controllerLastName = 'controller.lastName',
  controllerDoB = 'controller.dob',
  controllerAddress = 'controller.address',
  controllerLocation = 'controller.location',
  controllerIdentity = 'controller.identity',
  controllerHerebyCertified = 'controller.herebyCertified',
  beneficialOwnersIsExists = 'beneficialOwners.isExists',
  beneficialOwnersIsNotExistsApprove = 'beneficialOwners.isNotExistsApprove',
  beneficialOwnersList = 'beneficialOwners.list',
  bankForPayments = 'bank.forPayments',
  paperCheckAddressCheck = 'paperCheck.addressCheck',
  paperCheckAddress = 'paperCheck.address',
  paperCheckLocation = 'paperCheck.location',
  paperCheckEin = 'paperCheck.ein',
  paperCheckBank = 'paperCheck.bank',
  paperCheckBankAddress = 'paperCheck.bankAddress',
  paperCheckBankLocation = 'paperCheck.bankLocation',
  paperCheckPrintInfo = 'paperCheck.printInfo',
  paperCheckCompanyLogo = 'paperCheck.companyLogo',
  paperCheckSign = 'paperCheck.sign',
  paperCheckPhone = 'paperCheck.phone',
  paperCheckDelivery = 'paperCheck.delivery',
  documentPersonal = 'document.personal',
  documentBusiness = 'document.business',
  documentController = 'document.controller',
  documentDba = 'document.dba',
  documentBeneficialOwner = 'document.beneficialOwner',
  retryPersonal = 'retry.personal',
  retryBusinessSoleProprietorship = 'retry.businessSoleProprietorship',
  retryBusiness = 'retry.business',
  retryController = 'retry.controller',
}

export const SIGN_UP_PERSONAL_LOCATION_INFO_REQUIREMENTS: AccountRequirement[] = [AccountRequirement.locationPersonal];

export const SIGN_UP_PERSONAL_IDENTITY_INFO_REQUIREMENTS: AccountRequirement[] = [AccountRequirement.personalSsnFull];

export const SIGN_UP_BUSINESS_INFO_REQUIREMENTS: AccountRequirement[] = [
  AccountRequirement.businessUrl,
  AccountRequirement.businessDba,
  AccountRequirement.businessType,
  AccountRequirement.businessClassification,
  AccountRequirement.businessIndustry,
  AccountRequirement.businessAskAboutAddress,
  AccountRequirement.businessAddress,
  AccountRequirement.businessLocation,
  AccountRequirement.businessSsnLastFour,
  AccountRequirement.businessEin,
  AccountRequirement.businessAskAboutController,
  AccountRequirement.businessPhone,
];

export const SIGN_UP_CONTROLLER_INFO_REQUIREMENTS: AccountRequirement[] = [
  AccountRequirement.controllerTitle,
  AccountRequirement.controllerFirstName,
  AccountRequirement.controllerLastName,
  AccountRequirement.controllerDoB,
  AccountRequirement.controllerAddress,
  AccountRequirement.controllerLocation,
  AccountRequirement.controllerIdentity,
  AccountRequirement.controllerHerebyCertified,
];

export const SIGN_UP_BENEFICIAL_OWNERS_INFO_REQUIREMENTS: AccountRequirement[] = [
  AccountRequirement.beneficialOwnersIsExists,
  AccountRequirement.beneficialOwnersIsNotExistsApprove,
  AccountRequirement.beneficialOwnersList,
  AccountRequirement.documentBeneficialOwner,
];

export const SIGN_UP_BANK_INFO_REQUIREMENTS: AccountRequirement[] = [AccountRequirement.bankForPayments];

export const SIGN_UP_PAPER_CHECK_INFO_REQUIREMENTS: AccountRequirement[] = [
  AccountRequirement.paperCheckAddressCheck,
  AccountRequirement.paperCheckAddress,
  AccountRequirement.paperCheckLocation,
  AccountRequirement.paperCheckEin,
  AccountRequirement.paperCheckBank,
  AccountRequirement.paperCheckBankAddress,
  AccountRequirement.paperCheckBankLocation,
  AccountRequirement.paperCheckPrintInfo,
  AccountRequirement.paperCheckCompanyLogo,
  AccountRequirement.paperCheckSign,
  AccountRequirement.paperCheckPhone,
  AccountRequirement.paperCheckDelivery,
];

export const REQUIREMENTS_SIGN_UP_DOCUMENT: AccountRequirement[] = [
  AccountRequirement.documentPersonal,
  AccountRequirement.documentBusiness,
  AccountRequirement.documentController,
  AccountRequirement.documentDba,
];

export const REQUIREMENTS_SIGN_UP_RETRY_PERSONAL: AccountRequirement[] = [AccountRequirement.retryPersonal];

export const REQUIREMENTS_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP: AccountRequirement[] = [
  AccountRequirement.retryBusinessSoleProprietorship,
];

export const REQUIREMENTS_SIGN_UP_RETRY_BUSINESS: AccountRequirement[] = [
  AccountRequirement.retryBusiness,
  AccountRequirement.retryController,
];

export const SIGN_UP_REQUIREMENTS: AccountRequirement[] = [
  ...SIGN_UP_PERSONAL_LOCATION_INFO_REQUIREMENTS,
  ...SIGN_UP_PERSONAL_IDENTITY_INFO_REQUIREMENTS,
  ...SIGN_UP_BUSINESS_INFO_REQUIREMENTS,
  ...SIGN_UP_CONTROLLER_INFO_REQUIREMENTS,
  ...SIGN_UP_BENEFICIAL_OWNERS_INFO_REQUIREMENTS,
  ...SIGN_UP_BANK_INFO_REQUIREMENTS,
  ...SIGN_UP_PAPER_CHECK_INFO_REQUIREMENTS,
  ...REQUIREMENTS_SIGN_UP_DOCUMENT,
  ...REQUIREMENTS_SIGN_UP_RETRY_PERSONAL,
  ...REQUIREMENTS_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP,
  ...REQUIREMENTS_SIGN_UP_RETRY_BUSINESS,
];
