import {
  AccountRequirement,
  REQUIREMENTS_SIGN_UP_DOCUMENT,
  REQUIREMENTS_SIGN_UP_RETRY_BUSINESS,
  REQUIREMENTS_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP,
  REQUIREMENTS_SIGN_UP_RETRY_PERSONAL,
  SIGN_UP_BANK_INFO_REQUIREMENTS,
  SIGN_UP_BENEFICIAL_OWNERS_INFO_REQUIREMENTS,
  SIGN_UP_BUSINESS_INFO_REQUIREMENTS,
  SIGN_UP_CONTROLLER_INFO_REQUIREMENTS,
  SIGN_UP_PAPER_CHECK_INFO_REQUIREMENTS,
  SIGN_UP_PERSONAL_IDENTITY_INFO_REQUIREMENTS,
  SIGN_UP_PERSONAL_LOCATION_INFO_REQUIREMENTS,
  SIGN_UP_REQUIREMENTS,
} from '../common/enums/account-requirement';

export function extractRequirementsByRequirements(
  currentRequirements: AccountRequirement[],
  expectedRequirements: AccountRequirement[],
): AccountRequirement[] {
  return currentRequirements.filter((requirement: AccountRequirement) => expectedRequirements.includes(requirement));
}

export function extractSignUpRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_REQUIREMENTS);
}

export function extractBusinessInfoRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_BUSINESS_INFO_REQUIREMENTS);
}

export function extractControllerInfoRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_CONTROLLER_INFO_REQUIREMENTS);
}

export function extractBeneficialOwnersInfoRequirements(
  currentRequirements: AccountRequirement[],
): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_BENEFICIAL_OWNERS_INFO_REQUIREMENTS);
}

export function extractBankInfoRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_BANK_INFO_REQUIREMENTS);
}

export function extractPaperCheckInfoRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, SIGN_UP_PAPER_CHECK_INFO_REQUIREMENTS);
}

export function extractDocumentRequirements(currentRequirements: AccountRequirement[]): AccountRequirement[] {
  return extractRequirementsByRequirements(currentRequirements, REQUIREMENTS_SIGN_UP_DOCUMENT);
}

export function isSignUpRequired(currentRequirements: AccountRequirement[]): boolean {
  return extractSignUpRequirements(currentRequirements).length > 0;
}

export function isSignUpRequiredByRequirements(
  currentRequirements: AccountRequirement[],
  expectedRequirements: AccountRequirement[],
): boolean {
  for (const requirement of currentRequirements) {
    if (expectedRequirements.includes(requirement)) {
      return true;
    }
  }

  return false;
}

export function isSignUpPersonalLocationInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_PERSONAL_LOCATION_INFO_REQUIREMENTS);
}

export function isSignUpPersonalIdentityInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_PERSONAL_IDENTITY_INFO_REQUIREMENTS);
}

export function isSignUpBusinessInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_BUSINESS_INFO_REQUIREMENTS);
}

export function isSignUpControllerInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_CONTROLLER_INFO_REQUIREMENTS);
}

export function isSignUpBeneficialOwnersInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_BENEFICIAL_OWNERS_INFO_REQUIREMENTS);
}

export function isSignUpBankInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_BANK_INFO_REQUIREMENTS);
}

export function isSignUpPaperCheckInfoRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, SIGN_UP_PAPER_CHECK_INFO_REQUIREMENTS);
}

export function isSignUpDocumentRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, REQUIREMENTS_SIGN_UP_DOCUMENT);
}

export function isSignUpRetryPersonalRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, REQUIREMENTS_SIGN_UP_RETRY_PERSONAL);
}

export function isSignUpRetryBusinessSoleProprietorshipRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, REQUIREMENTS_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP);
}

export function isSignUpRetryBusinessRequired(currentRequirements: AccountRequirement[]): boolean {
  return isSignUpRequiredByRequirements(currentRequirements, REQUIREMENTS_SIGN_UP_RETRY_BUSINESS);
}
